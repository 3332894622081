import { useContext, useState } from "react";
import styled from "@emotion/styled";
import StyledText from "../Text/StyledText";
import TermsAndConditions from "../Result/TermsAndConditions";
import {
  BrandingContext,
  BrandingContextType,
} from "../../contexts/BrandingContext";
import {
  VenueConfigContext,
  VenueConfigContextType,
} from "../../contexts/VenueConfigContext";
import { get, set } from "local-storage";
import YSButton from "../YSButton";
import YSLogo from "../YSLogo";
import YSInput from "../YSInput";
import YSVerticalSpacer from "../YSVerticalSpacer";

export const player_name_key = "ys-player-name";
export const player_email_key = "ys-player-email";
export const player_age_key = "ys-player-age";
export const player_dob_key = "ys-player-dob";

const StyledWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 1.5rem;
  border-radius: 20px;
  text-align: center;
`;

const StyledLogoContainer = styled.div`
  width: 84px;
  height: 84px;
  margin: 2.5rem auto;
`;

const StyledErrorText = styled(StyledText)`
  color: darkred;
`;

const getNameStore = (): string => {
  const name = get<string>(player_name_key);
  return name;
};
const setNameStore = (name: string): string => {
  set<string>(player_name_key, name);
  return getNameStore();
};

const getEmailStore = (): string => {
  const email = get<string>(player_email_key);
  return email;
};
const setEmailStore = (email: string): string => {
  set<string>(player_email_key, email);
  return getEmailStore();
};

const getAgeStore = (): number => {
  const age = get<number>(player_age_key);
  return age;
};
const setAgeStore = (age: number): number => {
  set<number>(player_age_key, age);
  return getAgeStore();
};

const getDobStore = (): Date => {
  const dob = get<Date>(player_dob_key);
  return dob;
};
const setDobStore = (dob: Date): Date => {
  set<Date>(player_dob_key, dob);
  return getDobStore();
};

const formatDateForHtmlInput = (date: Date | null): string => {
  if (!date) return "";
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

interface CaptureAllProps {
  onFinished: (type: string) => void;
  emailCollectionEnabled: boolean;
  nameCollectionEnabled: boolean;
  ageCollectionEnabled: boolean;
  dobCollectionEnabled: boolean;
}

const CaptureAll = ({
  onFinished,
  emailCollectionEnabled,
  nameCollectionEnabled,
  ageCollectionEnabled,
  dobCollectionEnabled,
}: CaptureAllProps) => {
  const { theme: branding } = useContext(
    BrandingContext
  ) as BrandingContextType;
  const { config: venueConfig } = useContext(
    VenueConfigContext
  ) as VenueConfigContextType;

  const [name, setName] = useState<string | null>(null);
  const [nameErrorMessage, setNameErrorMessage] = useState<string | null>(null);
  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };
  const validateName = (name: string) => {
    return String(name).length > 2;
  };

  const [email, setEmail] = useState<string | null>(null);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(
    null
  );
  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };
  const validateEmail = (email: string) => {
    return !!String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const yearBounds = [new Date().getFullYear() - 100, new Date().getFullYear()];
  const [year, setYear] = useState<number | null>(null);
  const [age, setAge] = useState<number | null>(null);
  const [ageErrorMessage, setAgeErrorMessage] = useState<string | null>(null);
  const handleYearChange = (event: any) => {
    setYear(parseInt(event.target.value));
  };

  const [dob, setDob] = useState<Date | null>(null);
  const [dobErrorMessage, setDobErrorMessage] = useState<string | null>(null);
  const handleDobChange = (event: any) => {
    const date = new Date(event.target.value);
    setDob(date);
  };

  const onPressedPlay = (event: any) => {
    event.preventDefault();

    if (nameCollectionEnabled) {
      if (!name) {
        setNameErrorMessage("Please check your entry!");
        return;
      }

      if (!validateName(name)) {
        setNameErrorMessage("Invalid name, please check your entry!");
        return;
      }
      setName(name);
      setNameStore(name);
    }

    if (emailCollectionEnabled) {
      if (!email) {
        setEmailErrorMessage("Please check your entry!");
        return;
      }

      if (!validateEmail(email)) {
        setEmailErrorMessage("Invalid email address, please check your entry!");
        return;
      }
      setEmail(email);
      setEmailStore(email);
    }

    if (ageCollectionEnabled) {
      if (!year) {
        setAgeErrorMessage("Please check your entry!");
        return;
      }
      let age = new Date().getFullYear() - year;
      if (
        venueConfig.playerAgeMinRequired &&
        age < venueConfig.playerAgeMinRequired
      ) {
        setAgeErrorMessage(
          `Sorry, you must be at least ${venueConfig.playerAgeMinRequired!} to play`
        );
        return;
      } else if (age <= 0 || age > 85) {
        setAgeErrorMessage("Please try again!");
        return;
      }
      setAge(age);
      setAgeStore(age);
    }

    if (dobCollectionEnabled) {
      if (!dob) {
        setDobErrorMessage("Please provide a date of birth!");
        return;
      }

      if (
        dob.getFullYear() <= 1920 ||
        dob.getFullYear() >= new Date().getFullYear() - 10
      ) {
        setDobErrorMessage("Sorry, we can't accept that date of birth!");
        return;
      }
      setDob(dob);
      setDobStore(dob);
    }

    onFinished("capture-all"); //TODO: Chase
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <StyledWrapper>
        <StyledLogoContainer style={{ width: 84, height: 84 }}>
          <YSLogo />
        </StyledLogoContainer>
        <StyledText style={{ color: branding.backgroundTextColor }}>
          Please enter your details to spin!
        </StyledText>
        <YSVerticalSpacer />
        {nameCollectionEnabled && (
          <>
            {nameErrorMessage ? (
              <>
                <StyledErrorText
                  style={{ color: branding.backgroundTextColor }}
                >
                  {nameErrorMessage}
                </StyledErrorText>
              </>
            ) : (
              <StyledText style={{ color: branding.backgroundTextColor }}>
                Name
              </StyledText>
            )}
            <YSInput
              textColor={branding.wheelMainColor}
              placeholderTextColor={branding.wheelMainColor}
              placeholder="Name"
              type="text"
              value={name === null ? "" : name}
              onChange={handleNameChange}
              width={90}
            />
            <YSVerticalSpacer />
          </>
        )}
        {emailCollectionEnabled && (
          <>
            {emailErrorMessage ? (
              <>
                <StyledErrorText
                  style={{ color: branding.backgroundTextColor }}
                >
                  {emailErrorMessage}
                </StyledErrorText>
              </>
            ) : (
              <StyledText style={{ color: branding.backgroundTextColor }}>
                Email
              </StyledText>
            )}
            <YSInput
              textColor={branding.wheelMainColor}
              placeholderTextColor={branding.wheelMainColor}
              placeholder="Email"
              type="email"
              value={email === null ? "" : email}
              onChange={handleEmailChange}
              width={90}
            />
            <YSVerticalSpacer />
          </>
        )}
        {ageCollectionEnabled && (
          <>
            {ageErrorMessage ? (
              <>
                <StyledErrorText
                  style={{ color: branding.backgroundTextColor }}
                >
                  {ageErrorMessage}
                  <br />
                  visit{" "}
                  <a
                    target="_blank"
                    href="https://www.drinkaware.co.uk/"
                    rel="noreferrer"
                  >
                    drinkaware.co.uk
                  </a>
                </StyledErrorText>
              </>
            ) : (
              <StyledText style={{ color: branding.backgroundTextColor }}>
                Year of Birth
              </StyledText>
            )}
            <YSInput
              textColor={branding.wheelMainColor}
              placeholderTextColor={branding.wheelMainColor}
              placeholder="YYYY"
              type="number"
              min={yearBounds[0]}
              max={yearBounds[1]}
              step="1"
              value={year === null ? "" : year}
              onChange={handleYearChange}
              width={90}
            />
            <YSVerticalSpacer />
          </>
        )}
        {dobCollectionEnabled && (
          <>
            {dobErrorMessage ? (
              <>
                <StyledErrorText
                  style={{ color: branding.backgroundTextColor }}
                >
                  {dobErrorMessage}
                </StyledErrorText>
              </>
            ) : (
              <StyledText style={{ color: branding.backgroundTextColor }}>
                Date of Birth
              </StyledText>
            )}
            <YSInput
              textColor={branding.wheelMainColor}
              placeholderTextColor={branding.wheelMainColor}
              placeholder="YYYY"
              type="date"
              value={formatDateForHtmlInput(dob)}
              onChange={handleDobChange}
              width={90}
            />
          </>
        )}
        <YSVerticalSpacer />
        <YSButton text="Play" expandWidth={true} onPressed={onPressedPlay} />
        <TermsAndConditions />
        <YSVerticalSpacer />
      </StyledWrapper>
    </div>
  );
};

export default CaptureAll;
