import CaptureAll from "./CaptureAll";

interface Props {
  playerAgeCollectionEnabled: boolean;
  playerEmailCollectionEnabled: boolean;
  playerNameCollectionEnabled: boolean;
  playerDobCollectionEnabled: boolean;
  onPreGameFinished: () => void;
}

const PreGame = ({
  onPreGameFinished,
  playerAgeCollectionEnabled,
  playerEmailCollectionEnabled,
  playerNameCollectionEnabled,
  playerDobCollectionEnabled,
}: Props) => {
  return (
    <CaptureAll
      onFinished={onPreGameFinished}
      emailCollectionEnabled={playerEmailCollectionEnabled}
      nameCollectionEnabled={playerNameCollectionEnabled}
      ageCollectionEnabled={playerAgeCollectionEnabled}
      dobCollectionEnabled={playerDobCollectionEnabled}
    />
  );
};

export default PreGame;
