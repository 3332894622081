import axios from "axios";

export interface StartData {
  playRestrictionTimePeriod?: number;
  playRestrictionSpinLimit?: number;
  playRestrictionMessage?: string;
}

const startSession = async (venueShortName: string, cardId: string) => {
  const response = await axios.get<StartData>(
    `${process.env.REACT_APP_VENUES_API}/public/venues/${venueShortName}/cards/${cardId}`
  );
  return response.data;
};

export interface CreateTicketRequest {
  venueShortName: string;
  cardId: string;
}

export interface TicketBaseDto {
  ticketId: string;
}

const createTicketSession = async ({
  venueShortName,
  cardId,
}: CreateTicketRequest) => {
  const response = await axios.post<TicketBaseDto>(
    `${process.env.REACT_APP_VENUES_API}/public/venues/${venueShortName}/cards/${cardId}/tickets`
  );
  return response.data;
};

export interface VenueDto {
  ticketId: string;
  venue: Venue;
  campaign: Campaign;
  prizes: Prize[];
  employee: Employee;
}

const fetchVenue = async (venueShortName: string) => {
  const response = await axios.get<VenueDto>(
    `${process.env.REACT_APP_VENUES_API}/public/venues/${venueShortName}`
  );
  return response.data;
};

export interface Ticket {
  id: string;
  shouldCollectPlayerAge: boolean;
  shouldCollectPlayerEmail: boolean;
  shouldCollectPlayerName: boolean;
  shouldCollectPlayerDob: boolean;
  outcome: string;
  image: string;
  venueId: string;
  employeeName: string;
  table: string;
  campaignPrize: Prize;
  name: string;
  shortName: string;
  displayText: string;
  redemptionNotes: string;
  redemptionLink: string;
  issuedAt: Date;
  playedAt?: Date;
  redeemedAt?: Date;
}

const fetchTicket = async (venueShortName: string, ticketCode: string) => {
  const response = await axios.get<Ticket>(
    `${process.env.REACT_APP_VENUES_API}/public/venues/${venueShortName}/tickets/${ticketCode}`
  );
  return response.data;
};

export interface Prize {
  outcome: string;
  name: string;
  shortName: string;
  displayText: string;
  image: string;
  redemptionNotes: string;
}

const fetchPrizes = async (venueId: number) => {
  const response = await axios.get<Prize[]>(
    `${process.env.REACT_APP_VENUES_API}/public/venues/${venueId}/prizes`
  );
  return response.data;
};

export interface QuizRoundRequest extends PlayRequest {
  answer: string;
}

export interface QuizRoundResponse {}

const playQuizRound = async ({ venueShortName, ticketId }: PlayRequest) => {
  const response = await axios.post<Ticket>(
    `${process.env.REACT_APP_VENUES_API}/public/venues/${venueShortName}/tickets/${ticketId}/quizRound/play`
  );
  return response.data;
};

const submitQuizRoundAnswer = async ({
  venueShortName,
  ticketId,
  answer,
}: QuizRoundRequest) => {
  const response = await axios.post<Ticket>(
    `${process.env.REACT_APP_VENUES_API}/public/venues/${venueShortName}/tickets/${ticketId}/quizRound/answers`,
    { quizRoundAnswer: answer },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
  return response.data;
};

export interface PlayRequest {
  venueShortName: string;
  ticketId: string;
  deviceId?: string;
  playerAge?: number;
  playerEmail?: string;
  playerName?: string;
  playerDob?: Date;
}

const playTicket = async ({
  venueShortName,
  ticketId,
  deviceId,
  playerAge,
  playerEmail,
  playerName,
  playerDob,
}: PlayRequest) => {
  const response = await axios.post<Ticket>(
    `${process.env.REACT_APP_VENUES_API}/public/venues/${venueShortName}/tickets/${ticketId}/play`,
    {
      deviceId,
      playerAge,
      playerEmail,
      playerName,
      playerDob,
    },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
  return response.data;
};

const claimPrize = async ({ venueShortName, ticketId }: PlayRequest) => {
  const response = await axios.post<Ticket>(
    `${process.env.REACT_APP_VENUES_API}/public/venues/${venueShortName}/tickets/${ticketId}/prizeClaims`,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
  return response.data;
};

const submitSurveyAnswer = async ({
  venueShortName,
  ticketId,
  answer,
}: QuizRoundRequest) => {
  const response = await axios.post<Ticket>(
    `${process.env.REACT_APP_VENUES_API}/public/venues/${venueShortName}/tickets/${ticketId}/surveyAnswers`,
    { surveyAnswer: answer },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
  return response.data;
};

export {
  startSession,
  createTicketSession,
  fetchVenue,
  fetchTicket,
  fetchPrizes,
  playTicket,
  claimPrize,
  playQuizRound,
  submitQuizRoundAnswer,
  submitSurveyAnswer,
};
